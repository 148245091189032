import './AddUserForm.css'
import React, { useState } from 'react'
import { ButtonToolbar, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { Crop } from '../../imageCrop/Crop'
import useAuth from '../../../hooks/useAuth'
import ExtensionForm from '../ExtensionForm'
import { BsPersonFillLock } from "react-icons/bs"
import { FaUserShield } from "react-icons/fa6"
import { PassChange } from '../../passChange/PassChange'
import { StaffPermissions } from '../../staffPermissions/StaffPermissions'


// import { Dropdown } from 'semantic-ui-react'

const AddUserForm = (props) => {

    const { auth } = useAuth()

    const {
        imageData, handleSubmit, handleClose,
        add_img, setResult, result, userID,
        setImageData, setImageName,
        setSrcImg, srcImg, handleImage,
        name, setName, post, setPost, edit,
        mobile, setMobile, email, setEmail,
        staffSlotCount, permissions,
        setPermissions, getStaffList
    } = props

    const [showImageEdit, setShowImageEdit] = useState(false)
    const [numberModal, setNumberModal] = useState(false)

    const [showPC, setShowPC] = useState(false)
    const [showPermissions, setShowPermissions] = useState(false)

    const closeImagemodel = () => {
        handleClose()
        setShowImageEdit(false)
        setResult("")
        setSrcImg("")
        setImageData("")
        setImageName("")
    }


    const handleImageSubmit = (e) => {
        e.preventDefault()
        // Swal.fire("", "Image added !!!", "success")
        setShowImageEdit(false)
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    }

    const handleDrop = (event) => {
        // console.log(event.target.files[0])
        event.preventDefault();
        var files = event.dataTransfer.files;
        var fileInput = event.target;
        fileInput.files = files;
        // console.log(event)
        handleImage(event);
    }
    const handleNumberClose = () => {
        setNumberModal(false)
    }

    // const options = [
    //     { key: 1, text: 'Update Lead', value: 1 },
    //     { key: 2, text: 'Assign Lead', value: 2 },
    //     { key: 3, text: 'Call Lead', value: 3 },
    //     { key: 4, text: "None", value: 4 },

    // ]
    const popoverHoverFocus = (
        <Popover style={{
            "backgroundColor": "grey",
            "borderColor": "#light", "color": "#ffffff",
            "borderRadius": "0.5rem", "padding": "0.2rem 0.5rem", "marginTop": "0.1rem"
        }}>
            Change Password
        </Popover>
    );

    const permissionPopoverHoverFocus = (
        <Popover style={{
            "backgroundColor": "grey",
            "borderColor": "#light", "color": "#ffffff",
            "borderRadius": "0.5rem", "padding": "0.2rem 0.5rem", "marginTop": "0.1rem"
        }}>
            Change Permissions
        </Popover>
    );

    return (
        <div className="">
            <div className="row align-items-center" style={{ justifyContent: "space-between" }}>
                <div className="h3 fw-bolder col-md-10 col-12">{props.title}</div>
                {(auth.role === 'Company' && edit) &&
                    (
                        <>
                            <ButtonToolbar onClick={() => setShowPC(true)} className='col-1 p-2'>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={popoverHoverFocus}
                                >
                                    <div className='btn btn-outline-primary rounded-4 pt-2' style={{ width: "auto", aspectRatio: "1/1" }}><BsPersonFillLock size='1.5rem' /></div>
                                </OverlayTrigger>
                            </ButtonToolbar>    
                            <ButtonToolbar onClick={() => setShowPermissions(true)} className='col-1 p-2'>
                                <OverlayTrigger
                                    trigger={['hover', 'focus']}
                                    placement="bottom"
                                    overlay={permissionPopoverHoverFocus}
                                >
                                    <div className='btn btn-primary rounded-4 pt-2' style={{ width: "auto", aspectRatio: "1/1" }}><FaUserShield size='1.5rem' /></div>
                                </OverlayTrigger>
                            </ButtonToolbar>
                        </>
                    )
                }
                {(auth.role === 'Company' && !edit) &&
                    <div className="col-md-6 col-12 text-md-end">
                        Staff creation slots left : <b className="text-danger">{props.staffSlotCount}</b>
                    </div>
                }

            </div>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <div className=' p-3'>
                            <label htmlFor="uploadStaffImg" className='adduser-img col-12'>

                                <img onClick={() => setShowImageEdit(true)} src={srcImg}
                                    // className={selected != "" ? "w-100" : ""} 
                                    alt="" />

                            </label>
                            <div id='uploadStaffImg' hidden className=' form-control' />
                        </div>
                        {(auth.role === 'Company' && userID) &&
                            <div
                                className="btn row btn-secondary py-0 px-4 my-2"
                                style={{ fontSize: '14px', width: "7.6rem" }}
                                onClick={() => setNumberModal(true)}
                            >
                                Caller Id
                            </div>
                        }
                    </div>
                    <div className="col-md-8">
                        <div className="row my-4">
                            <div className="col-md-7 px-1">
                                <Form.Group className='' controlId='staffName'>
                                    <Form.Label className='fw-bolder'>Name</Form.Label>
                                    <Form.Control value={name} onChange={(e) => setName(e.target.value)} className='py-2' placeholder="Eg: Beserac" />
                                </Form.Group>
                            </div>
                            <div className="col-md-5 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Role</Form.Label>
                                    <Form.Select
                                        className={"mouse-pointer type-dropdown "
                                            // + window.innerWidth<768&&"mobile-view-dropdown "
                                        }
                                        disabled={edit}
                                        size="md"
                                        value={post}
                                        onChange={(e) => setPost(e.target.value)}
                                    >
                                        <option className="menu" value="Sub Admin">Sub Admin</option>
                                        <option className="menu" value="Agent">Agent</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-5 px-1">
                                <Form.Group className='' controlId='staffPhone'>
                                    <Form.Label className='fw-bolder'>Phone number</Form.Label> (Password)
                                    <Form.Control type='tel' value={mobile} onChange={(e) => setMobile(e.target.value)} className='py-2' placeholder="+91 9876543210" />
                                </Form.Group>
                            </div>
                            <div className="col-7 px-1">
                                <Form.Group className='' controlId='staffPost'>
                                    <Form.Label className='fw-bolder'>Email</Form.Label> (Username)
                                    <Form.Control type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='py-2' placeholder="user@example.com" />
                                </Form.Group>
                            </div>
                        </div>
                        {/* {props.admin &&  */}
                        <div className="row my-4">
                            <div className="col-md-6 px-1">
                                {/* <Dropdown
                                placeholder='Select access'
                                 className='col-10'
                                  clearable options={options} 
                                  selection /> */}

                            </div>
                            <div className="row col-md-6">
                                <div className="col-6">
                                    <button onClick={handleClose} type='button' className='btn btn-outline-primary adduser-btn col-12'>Cancel</button>
                                </div>
                                <div className="col-6">
                                    <button type='submit' className={`btn btn-primary adduser-btn col-12 ${auth.theme === 'companyTheme' ? '' : 'agent'}`}>Save</button>
                                </div>
                            </div>
                        </div>
                        {/* } */}

                    </div>
                </div>
                {/* {!props.admin &&
                    <div className="row adduser-btn-container">
                        <div className="col-md-6"></div>
                        <div className="row col-md-6">
                            <div className="col-md-6">
                                <button onClick={handleClose} type='button' className='btn btn-outline-primary adduser-btn col-12'>Cancel</button>
                            </div>
                            <div className="col-md-6">
                                <button type='submit' className='btn btn-primary adduser-btn col-12'>Save</button>
                            </div>
                        </div>
                    </div>} */}
            </Form>
            <Modal
                size="lg"
                centered
                show={showImageEdit}
                onHide={closeImagemodel}
                contentClassName="lead-desc-modal px-5"
            >
                <Modal.Body>
                    <div className='mx-3'>
                        <Form onSubmit={handleImageSubmit}>
                            <div className="row my-2">
                                <div className="h4 fw-bolder">Upload Profile Image</div>
                            </div>
                            <div className="row my-2">
                                <div onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e)} className="lead-image-upload text-center p-4">
                                    {imageData ?
                                        <>
                                            <Crop {...{ result, setResult, imageData, setImageData, setSrcImg, srcImg }} />
                                        </> :
                                        <>
                                            <div className="mb-3">
                                                <img className='uploadPreview' src={add_img} alt="" />
                                            </div>
                                            <div className="">
                                                <div>Drag & Drop Files Here</div>
                                                <div>or</div>
                                            </div>
                                        </>
                                    }
                                    <div className="mt-3">
                                        <div>
                                            <label htmlFor='leadImage' className='col-6 btn btn-outline-primary browse-btn'>Browse Files</label>
                                            <input id='leadImage' onChange={(e) => handleImage(e)} hidden className='form-control' type="file" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="px-0    ">
                                    <div onClick={handleClose} className='btn btn-pill col-md-2 col-12 my-2 btn-outline-primary float-end'>Cancel</div>
                                    <button
                                        onClick={handleImageSubmit}
                                        className={
                                            window.innerWidth > 768 ?
                                                `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end mx-3 ${auth.theme === 'companyTheme' ? '' : 'agent'}` :
                                                `btn btn-pill col-md-2 col-12 my-2 btn-primary float-end ${auth.theme === 'companyTheme' ? '' : 'agent'}`
                                        }
                                    >Submit</button>
                                </div>
                            </div>
                        </Form >
                    </div >
                </Modal.Body>
            </Modal>
            <Modal
                show={numberModal}
                onHide={handleNumberClose}
                centered={true}
                size="md"
                contentClassName=""
            >
                <Modal.Body>
                    <ExtensionForm setNumberModal={setNumberModal} userID={userID} />
                </Modal.Body>
            </Modal>
            <PassChange userID={userID} setShowPC={setShowPC} showPC={showPC} />
            <StaffPermissions 
                userID={userID}
                setShowPermissions={setShowPermissions} 
                showPermissions={showPermissions} 
                setPermissions={setPermissions}
                permissions={permissions}
                auth={auth}
                getStaffList={getStaffList} 
            />
        </div>
    )
}

export default AddUserForm