import { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";
import Swal from "sweetalert2";
// import useAuth from "../../../hooks/useAuth";
import useCompanyServices from "../../services/companyServices";

function PermissionRaw({ userID, data, permissionData, setPermissionData, getStaffList }) {
  const [checked, setChecked] = useState(false);
  console.log(data);
  const { postStaffPermissions } = useCompanyServices();

  useEffect(() => {
    setChecked(data?.status);
  }, [data]);

  const handleCheck = async (code) => {
    try {
      console.log(data, code, checked)
      let response;
      let permissions = {...permissionData};
      permissions[code] = !checked;
      response = await postStaffPermissions(userID, permissions);
      console.log(response);
      if (response && response.success) {
        Swal.fire("", "Permission Changed Successfully", "success");
        getStaffList();
        setChecked(!checked);
      } else {
        Swal.fire("", "Some Errors occurred", "error");
      }
    } catch (err) {
      console.error("Error occurred while changing settings:", err);
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  return (
    <div className="row border-md-0 border ps-4">
      <div className="col-10 my-1 ps-4 p-1">{data?.label}</div>

      <div className="col-md-1 col-2 ps-md-1 pt-2">
        <Checkbox
          toggle
          checked={checked}
          className=""
          onChange={() => handleCheck(data?.code)}
        />
      </div>
    </div>
  )
}

export default PermissionRaw;
