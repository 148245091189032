import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import useCompanyServices from '../../services/companyServices'
import Swal from 'sweetalert2'
import './StaffPermissions.css'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import PermissionRaw from './PermissionRaw'


export const StaffPermissions = ({ userID, permissions, setShowPermissions,  showPermissions, auth, getStaffList }) => {
const { getCompanyProfile, changeGlobalCustomerView } = useCompanyServices();
    const [permissionData, setPermissionData] = useState(
        {
            list_all_leads : permissions.list_all_leads.status,
            assign_lead_sub_admin : permissions.assign_lead_sub_admin.status,
            assign_team_member : permissions.assign_team_member.status,
            create_new_sub_admin : permissions.create_new_sub_admin.status,
            delete_sub_admin : permissions.delete_sub_admin.status,
        }
    )

    useEffect(() => {
        // getProfile();
    }, []);

    const handleCancel = () =>{
        setShowPermissions(false)
    }

    return (
        <div>
            <Modal
                show={showPermissions}
                onHide={() => setShowPermissions(false)}
                centered={true}
                size="lg"
                contentClassName=""
            >
                <Modal.Body>
                    <>  
                        <div className="row bg-white">
                            <div className="settings-table-container bg-white">
                                <div>
                                    {window.innerWidth > 768 && (
                                        <div className="row p-2 ps-4 settings-table-header">
                                            <div className="col-10 ps-4">Permission</div>
                                            <div className="col-1">Status</div>
                                        </div>
                                    )}
                                </div>
                                <div className="my-2 settings-table-body">
                                    {permissions &&
                                        Object.keys(permissions).slice(0,2).map((key, index) => (
                                            <PermissionRaw
                                                userID={userID}
                                                data={permissions[key]}
                                                getStaffList={getStaffList}
                                                key={index}
                                                permissionData={permissionData}
                                                setPermissionData={setPermissionData}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <div onClick={handleCancel} className='btn-outline-primary btn mt-4 '>Close</div>
                                {/* <input type='submit' disabled={passCheck} value={"Change"} className='passC bg-warning btn mt-4'/> */}
                            </div> 
                        </div>
                    </>
                </Modal.Body>
            </Modal>
        </div>
    )
}
