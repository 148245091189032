import React, { useEffect, useState } from "react";
import OtherSettingsRaw from "./OtherSettingsRaw";
import useCompanyServices from "../../../services/companyServices";

const SourceSettings = ({ auth }) => {
  const [leadPrivacy, setLeadPrivacy] = useState(false);
  const [globalView, setGlobalView] = useState(false);
  const [globalCustomerView, setGlobalCustomerView] = useState(false);
  const [autoCall, setAutoCall] = useState(false);
  const { getCompanyProfile, changeGlobalCustomerView } = useCompanyServices();

  useEffect(() => {
    getProfile();
  }, []);

  const settings = [
    {
      name: "Hide lead contact details from agent",
      status: leadPrivacy,
      code: "leadPrivacy",
    },
    {
      name: "Display all leads for every staff member",
      status: globalView,
      code: "globalView",
    },
    {
      name: "Display all customers for every staff member",
      status: globalCustomerView,
      code: "globalCustomerView",
    },
    {
      name: "Automatic call generation while Meta lead generation",
      status: autoCall,
      code: "autoCall",
    },
  ];

  const getProfile = async () => {
    try {
      if (auth.role === "Company") {
        const response = await getCompanyProfile();
        setLeadPrivacy(response.data.data_privacy);
        setGlobalView(response.data.global_lead_view);
        setGlobalCustomerView(response.data.global_customer_view);
        setAutoCall(response.data.settings ? response.data.settings.auto_calling : false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="row my-3">
        <div className="settings-table-container">
          <div>
            {window.innerWidth > 768 && (
              <div className="row p-2 ps-4 settings-table-header">
                  <div className="col-10 ps-4">Permission</div>
                  <div className="col-1">Status</div>
              </div>
            )}
          </div>
          <div className="my-2 settings-table-body">
            {settings &&
              settings.map((data, index) => (
                <OtherSettingsRaw
                  data={data}
                  refresh={getCompanyProfile}
                  key={index}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceSettings;
