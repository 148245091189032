import useAxiosPrivate from "../hooks/useAxiosPrivate";

const useCompanyServices = () => {
    const axiosPrivate = useAxiosPrivate()

    //get company profile details
    const getCompanyProfile = async () => {
        const response = await axiosPrivate.get("/company/profile/")
        return response.data
    }

    
    //change company data privacy details
    const changeCompanyPrivacy = async () => {
        const response = await axiosPrivate.get("/company/lead_privacy_status/")
        return response.data
    }

    const changeGlobalLeadView = async () => {
        const response = await axiosPrivate.get("/company/global_lead_view_status/")
        return response.data
    }

    //global customer

    const changeGlobalCustomerView = async () => {
        const response = await axiosPrivate.get("/company/global_customer_view_status/");
        return response.data;
    };

    const changeAutoCall = async () => {
        const response = await axiosPrivate.get("/company/auto_call_status/");
        return response.data;
    };

    //get agent list to assign
    const getAgentAssignSubAdmin = async (id, choice) => {
        const response = await axiosPrivate.get("/company/agent_assign_subadmin/" + id + "/", {
            params: { "choice": choice }
        })
        return response.data
    }

    //get staff list for switching
    const getAllStaffList = async () => {
        const response = await axiosPrivate.get("/company/all_staff_list/")
        return response.data
    }

    //assign agents to sub admin
    const postAgentAssignSubAdmin = async (id, data) => {
        const response = await axiosPrivate.post("/company/agent_assign_subadmin/" + id + "/", data)
        return response.data
    }

    //change staff status
    const putStaffStatusChange = async (id, data = null) => {
        const response = await axiosPrivate.put("/company/staff_status_change/" + id + "/", data = null)
        return response.data
    }

    //edit company profile details
    const putCompanyProfile = async (data) => {
        const response = await axiosPrivate.put("/company/profile/", data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        return response.data
    }
    
    //edit staff passwords
    const putChangeStaffPassword = async (id,data) => {
        const response = await axiosPrivate.put("/account/company/change_password/user/"+id+"/", data)
        return response.data
    }

    //change staff permissions
    const postStaffPermissions = async (id,data) => {
        const response = await axiosPrivate.post("/account/staff/permission/"+id+"/", data)
        return response.data
    }


    //returns
    return {
        //other requests
        changeCompanyPrivacy,
        changeGlobalLeadView,
        changeGlobalCustomerView,
        changeAutoCall,

        //get requests
        getCompanyProfile,
        getAgentAssignSubAdmin,
        getAllStaffList,

        //post requests
        postAgentAssignSubAdmin,

        //put requests
        putStaffStatusChange,
        putCompanyProfile,
        putChangeStaffPassword,

        postStaffPermissions,
    }
}
export default useCompanyServices